
  import Cookies from 'js-cookie';
  import { defineComponent, inject, onMounted, ref, watch } from 'vue';
  import { ILazyLoadInstance } from 'vanilla-lazyload';

  const DAYS_IN_MONTH = 31;
  const DAYS_IN_YEAR = 365;
  const COOKIES_KEY_ACCEPTED = 'cookiesAccepted';
  const COOKIES_KEY_CLOSED = 'cookiesClosed';
  const CLASS_MEDIA_OVERLAY = 'cookies-info-overlay';
  const IGNORED_IFRAMES_SELECTOR = ':not(.iframe-no-cookies):not([src*="awareness.cloud.apg.at"]):not([src*="app.23degrees.eu"]):not([data-23src*="app.23degrees.eu"]):not([src*="transparency.apg.at"])';
  const IFRAMES_SELECTOR = `iframe${IGNORED_IFRAMES_SELECTOR}`;
  const IFRAMES_SELECTOR_NO_COOKIES = `iframe:not(${IFRAMES_SELECTOR})`;

  export default defineComponent({
    props: {
      title: {
        type: String,
        default: 'Cookie policy'
      },
      text: {
        type: String,
        default: 'We use cookies to ensure the best experience on the website.'
      },
      closeLabel: {
        type: String,
        default: 'Close'
      },
      acceptLabel: {
        type: String,
        default: 'Accept'
      },
      enableMessage: {
        type: String,
        default: 'Please enable cookies to view this content'
      }
    },
    setup(props) {
      const cookiesAccepted = ref(Cookies.get(COOKIES_KEY_ACCEPTED) === '1');
      let cookiesClosed = ref(Cookies.get(COOKIES_KEY_CLOSED) === '1');
      const lazyLoad: ILazyLoadInstance | undefined = inject('lazyLoad');

      function allowIFrames(scopeElem: ParentNode = document, iframesSelector: string = IFRAMES_SELECTOR) {
        let iframesInScope;

        if (scopeElem instanceof HTMLIFrameElement) {
            iframesInScope = [scopeElem];
        } else {
            iframesInScope = scopeElem.querySelectorAll(iframesSelector) as NodeListOf<HTMLIFrameElement>
        }

        if (!iframesInScope) {
          return;
        }

        [...iframesInScope].forEach((iframe: HTMLIFrameElement) => {
          const message = iframe.parentNode?.querySelector(`.${CLASS_MEDIA_OVERLAY}`);
          if (message) {
              message.parentNode?.removeChild(message);
          }
          iframe.setAttribute('lazy', 'true');
          iframe.style.display = 'block';
        });
      }

      function allowScripts() {
        for (const script of Array.from(document.scripts)) {
          if (script.type.toLowerCase() === 'text/plain') {
            const clonedScript = script.cloneNode(true) as HTMLScriptElement;
            const oldType = clonedScript.getAttribute('data-type');
            if (oldType) {
              clonedScript.setAttribute('type', oldType);
            } else {
              clonedScript.removeAttribute('type');
            }
            clonedScript.removeAttribute('data-type');
            script.parentNode?.replaceChild(clonedScript, script);
          }
        }
      }

      function disableIFrames(scopeElem: ParentNode = document, iframesSelector: string = IFRAMES_SELECTOR) {
        let iframesInScope;

        if (scopeElem instanceof HTMLIFrameElement) {
          iframesInScope = [scopeElem];
        } else {
          iframesInScope = scopeElem.querySelectorAll(iframesSelector) as NodeListOf<HTMLIFrameElement>;
        }

        if (!iframesInScope) {
          return;
        }

        const pTag = document.createElement('p');
        pTag.innerHTML = props.enableMessage;
        pTag.classList.add(`${CLASS_MEDIA_OVERLAY}-text`);
        const divTag = document.createElement('div');
        divTag.classList.add(CLASS_MEDIA_OVERLAY);
        divTag.appendChild(pTag);
        [...iframesInScope].forEach((iframe: HTMLIFrameElement) => {
          iframe.parentNode?.insertBefore(divTag.cloneNode(true), iframe);
          iframe.parentNode?.querySelector(`.${CLASS_MEDIA_OVERLAY}-text`)?.addEventListener('click', () => {
            cookiesClosed.value = false;
          });
          iframe.style.display = 'none';
        });
      }

      function watchCookiesAccepted(accepted: boolean) {
        if (accepted) {
          allowIFrames();
          allowScripts();
        } else {
          disableIFrames();
        }
        if (lazyLoad) {
          lazyLoad.update();
        }
      }

      function watchCookiesClosed(closed: boolean) {
        if (closed) {
          allowIFrames(document, IFRAMES_SELECTOR_NO_COOKIES);
        } else {
          disableIFrames(document, IFRAMES_SELECTOR_NO_COOKIES);
        }
        if (lazyLoad) {
          lazyLoad.update();
        }
      }

      onMounted(() => {
        // if (!cookiesAccepted.value) {
        //   disableIFrames();
        // }
        watchCookiesAccepted(cookiesAccepted.value);
        watchCookiesClosed(cookiesClosed.value);

        // disable e.g. iframes in lazy loaded components (swiper)
        const observer = new MutationObserver(function(mutationsList) {
            mutationsList.forEach(function(mutation) {
              mutation.addedNodes.forEach(function(addedNode) {
                  if (addedNode instanceof HTMLElement) {
                      if (cookiesAccepted.value) {
                          allowIFrames(addedNode);
                      } else {
                          disableIFrames(addedNode);
                      }

                      if (cookiesClosed.value) {
                          allowIFrames(addedNode, IFRAMES_SELECTOR_NO_COOKIES);
                      } else {
                          disableIFrames(addedNode, IFRAMES_SELECTOR_NO_COOKIES);
                      }

                      if (cookiesAccepted.value || cookiesClosed.value) {
                          if (lazyLoad) {
                              lazyLoad.update();
                          }
                      }
                  }
              });
          });
        });

        observer.observe(document.body, { subtree: true, childList: true });
      });

      watch(cookiesAccepted, (accepted) => {
        watchCookiesAccepted(accepted);
      });

      watch(cookiesClosed, (closed) => {
        watchCookiesClosed(closed);
      });

      return {
        cookiesAccepted,
        cookiesClosed
      };
    },
    methods: {
      accept() {
        this.cookiesClosed = true;
        Cookies.set(COOKIES_KEY_CLOSED, '1', {
          expires: DAYS_IN_YEAR,
          sameSite: 'strict',
          secure: true
        });
        this.cookiesAccepted = true;
        Cookies.set(COOKIES_KEY_ACCEPTED, '1', {
          expires: DAYS_IN_YEAR,
          sameSite: 'strict',
          secure: true
        });
      },

      close() {
        this.cookiesClosed = true;
        Cookies.set(COOKIES_KEY_CLOSED, '1', {
          expires: DAYS_IN_MONTH,
          sameSite: 'strict',
          secure: true
        });
      }
    }
  });
